// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBJ5Ppe6ItEUS4ogiteg_eDErWUmrPdvPI',
    authDomain: '<your-project-authdomain>',
    databaseURL: '<your-database-URL>',
    projectId: 'file-explorer-a303e',
    storageBucket: '<your-storage-bucket>',
    messagingSenderId: '<your-messaging-sender-id>'
  },
  //urlApi: 'http://127.0.0.1:8000/api/',
  //urlDoc: 'http://127.0.0.1:8000/',
  urlApi: 'http://www.folder-online-back.aina-tojosoa.com/api/',
  urlDoc: 'http://www.folder-online-back.aina-tojosoa.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
