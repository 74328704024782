import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';

import { TokenStorageService } from '../../service/token-storage.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  currentUser: any;

  constructor(private token: TokenStorageService, private authService: AuthService) { }

  ngOnInit() {

    this.currentUser = this.token.getUser();
  }
}
