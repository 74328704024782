import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { AuthService } from '../../../service/auth.service';
import generateUniqueId  from 'generate-unique-id';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  form: any = {};
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  load = false;

  constructor(private authService: AuthService, private userService: UserService) { }

  ngOnInit() {
  }

  onSubmit() {
    this.load = true;
    this.authService.register(this.form).subscribe(
      data => {
        this.isSuccessful = true;
        this.isSignUpFailed = false;
        this.form = {};
        this.load = false;
      },
      err => {
        this.load = false;
        this.errorMessage = "Changer l'email";
        this.isSignUpFailed = true;
      }
    );
  }

}
