import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SimpleModalService } from "ngx-simple-modal";
import { Item } from "../../model/item";
import { NewFolderComponent } from "../modals/new-folder/new-folder.component";
import { ConfirmComponent } from "../modals/confirm/confirm.component";
import { UploadFileComponent } from "../modals/upload-file/upload-file.component";
import { Observable, of } from "rxjs";
import { DataService } from "./../../service/data.service";
import { environment } from "src/environments/environment";
import { UserService } from "src/app/service/user.service";
import { TokenStorageService } from "src/app/service/token-storage.service";
import { RenameFolderComponent } from "../modals/rename-folder/rename-folder.component";
import { DeleteComponent } from "../modals/delete/delete.component";
@Component({
  selector: "app-treeview",
  templateUrl: "./treeview.component.html",
  styleUrls: ["./treeview.component.css"],
})
export class TreeviewComponent {
  private map = new Map<any, Item>();
  @Input() list = [];
  @Input() opened = new Set();
  @Input() matcher = (term, item) =>
    item.title.toLowerCase().includes(term.toLowerCase());
  @Output() addChild = new EventEmitter<any>();
  @Output() removeChild = new EventEmitter<any>();
  currentPath: string;
  found: Item[] = [];
  pdfView = false;
  private url = (environment as any).urlDoc;
  private imageExtension = ['jpeg', 'jpg', 'png', 'gif', 'tiff']
  load = false;
  viewer = "google";
  selectedType = "xlsx"; //'docx';
  private imageFile =false;
  private doc = "";
  // doc = 'http://localhost:8000/dossier_admin/flutter.docx';
  //doc = 'http://www.pdf995.com/samples/pdf.pdf';
  list$: Observable<Item[]>;
  constructor(
    private token: TokenStorageService,
    private SimpleModalService: SimpleModalService,
    private data: DataService,
    private userService: UserService
  ) {
    this.listFolder();
  }

  listFolder() {
    if (
      this.token.getUser().roles === "1" ||
      this.token.getUser().roles === "3"
    ) {
      this.userService.getFolder().subscribe((data) => {
        console.log(data);
        return of<Item[]>((this.list = data));
       
        
      });
    } else {
      this.userService
        .getFolderById(this.token.getUser().id)
        .subscribe((data) => {
          console.log(data);

          return of<Item[]>((this.list = data));
        });
    }
  }
  toggle(item) {
    this.opened.has(item.id)
      ? this.opened.delete(item.id)
      : !this.opened.add(item.id);
    if (item.isFolder == false) {
      this.pdfView = true;
      let x = item.path;
      var f = x.substr(0, x.lastIndexOf(".")) || x;
      var iz = f.split("/").join("|");
      console.log(x.split('.').pop());
      let ext = x.split('.').pop().toString().toLowerCase();
      if(this.imageExtension.includes(ext)) {
        this.imageFile = true;
      } else {
        this.imageFile = false;
      }
      
      this.doc = `${this.url}${x}`;
      /*       this.userService.showPdf(iz).subscribe(data => {
        
        var fileURL = URL.createObjectURL(data);
        
        this.pdfSrc = fileURL
      }) */
    }
  }

  getItemsAtParent(parentId) {
    return this.list.filter((item) => {
      return parentId ? item.parent === parentId : !item.parent;
    });
  }

  hasChildren(id) {
    const found = this.list.find((item) => item.parent === +id);
    return found;
  }
  collapseAll() {
    this.opened.clear();
  }

  expandAll() {
    const ids = this.list.map((item) => item.id);
    this.opened = new Set(ids);
  }

  show(id) {
    let item = "parent" in id ? id : this.find(+id);
    console.log(item);

    if (!item) {
      return;
    }

    while (item.parent) {
      this.opened.add(item.parent);
      item = this.find(item.parent);
    }
  }

  private find(id) {
    return this.list.find((item) => item.id === id);
  }

  searchReset() {
    this.found = [];
  }
  remove(el) {
    this.SimpleModalService.addModal(DeleteComponent, {
      title: "Delete File",
      message: "Confirm Delete",
    }).subscribe((isConfirmed) => {
      if (isConfirmed) {
        var removeIndex = this.list.map((item) => item.id).indexOf(el.id);
        ~removeIndex && this.list.splice(removeIndex, 1);
        let feed = {
          path: el.path,
          isFolder: el.isFolder,
        };
        this.userService.deleteFolder(el.id).subscribe((data) => {
          console.log(data);
        });
        this.userService.removeFolder(feed).subscribe((data) => {
          console.log(data);
        });
      }
    });
  }

  search(term) {
    this.found = this.list.filter((item) =>
      this.matcher.call(this, term, item)
    );
    this.found.forEach((item) => this.show(item));
  }

  createParentFolder() {
    this.SimpleModalService.addModal(NewFolderComponent, {
      title: "New folder",
      question: "Name Folder",
    }).subscribe((folder) => {
      if (folder != undefined) {
        let json = {
          name: folder,
        };
        this.load = true;
        let new_text = json.name.split(" ").join("_");
        let folders = {
          title: json.name,
          parent: 0,
          path: new_text,
          isFolder: true,
        };
        this.userService.postFolder(folders).subscribe((c) => {
          this.listFolder();
          this.load = false;
        });
      }
    });
  }
  shareFolder(e) {
    this.SimpleModalService.addModal(ConfirmComponent, {
      title: "Confirmation",
      question: "Do you really want to share this folder?",
    }).subscribe((message) => {
      if (message != undefined) {
        this.load = true;
        this.userService.shareFolder(e).subscribe((data) => {
          this.listFolder();
           this.load = false;
        });
        this.load = false;
      }
    });
    
  }
  showCreateFolder(el) {
    let parentPath = this.list.find((item) => item.id === el);
    if (parentPath.path.includes("/")) {
      let a = parentPath.path.substring(0, parentPath.path.indexOf("/"));
      this.SimpleModalService.addModal(NewFolderComponent, {
        title: "New Folder",
        question: "Name folder",
      }).subscribe((message) => {
        if (message != undefined) {
          this.load = true;
          let new_text = message.split(" ").join("_");
          let parentPath = this.list.find((item) => item.id === el);

          let feed = {
            title: message,
            parent: el,
            path: parentPath.path.split(" ").join("_") + "/" + new_text,
            isFolder: true,
          };
          this.userService.postFolder(feed).subscribe((data) => {
            this.listFolder();
             this.load = false;
          });
          this.load = false;
        }
      });
    } else {
      this.SimpleModalService.addModal(NewFolderComponent, {
        title: "New Folder",
        question: "Name folder",
      }).subscribe((message) => {
        if (message != undefined) {
          this.load = true;
          let new_text = message.split(" ").join("_");
          let parentPath = this.list.find((item) => item.id === el);

          let feed = {
            title: message,
            parent: el,
            path: parentPath.path.split(" ").join("_") + "/" + new_text,
            isFolder: true,
          };
          this.userService.postFolder(feed).subscribe((data) => {
            this.listFolder();
            console.log(data);
          });
          this.list.push(feed);
          this.load = false;
        }
      });
    }
  }
  showUploadFile(el) {
    
    this.SimpleModalService.addModal(UploadFileComponent, {
      title: "New File",
      question: "Name file",
    }).subscribe((message) => {
      if (message) {
        this.load = true;
      let nameFile =
        message.message + "." + message.upload[0].name.split(".")[1];

      if (message != undefined) {
        let parentPath = this.list.find((item) => item.id === el);
        if (parentPath.path.includes("/")) {
          let a = parentPath.path.substring(0, parentPath.path.indexOf("/"));
          let new_text = nameFile.split(" ").join("_");
          let formData = new FormData();

          formData.append("file", message.upload[0]);
          formData.append("msg", nameFile);
          formData.append("path", "/" + parentPath.path);
          this.userService.upload(formData).subscribe(
            (up) => console.log(up),
            (err) => console.log(err)
          );
          let feed = {
            title: new_text,
            parent: el,
            path: parentPath.path + "/" + new_text,
            isFolder: false,
          };
          this.userService.postFolder(feed).subscribe((data) => {
            this.listFolder();
            this.load = false;
          });
        } else {
          this.load = true;
          let new_text = nameFile.split(" ").join("_");
          let formData = new FormData();

          formData.append("file", message.upload[0]);
          formData.append("msg", nameFile);
          formData.append("path", "/" + parentPath.path);
          this.userService.upload(formData).subscribe(
            (up) => console.log(up),
            (err) => console.log(err)
          );

          let feed = {
            title: new_text,
            parent: el,
            path: parentPath.path + "/" + new_text,
            isFolder: false,
          };
          this.userService.postFolder(feed).subscribe((data) => {
            this.listFolder();
            this.load = false;
          });
        }
      }
    }
    });
  }

  renameFile(el) {
    console.log(el);

    var iz = el.title.split("dossier ").join("|");
    const oldTitle = el.title.split(" ").join("_");
    let parentPath = this.list.find((item) => item.id === el.id);
    let fichier = parentPath.path.split("/").pop();
    this.SimpleModalService.addModal(RenameFolderComponent, {
      title: "Edit File",
      question: "Name of file",
      message: fichier,
    }).subscribe((message) => {
      if (message != undefined) {
        let objIndex = this.list.findIndex((obj) => obj.id == el.id);
        //Update object's name property.
        this.list[objIndex].title = message;
        let new_text = message.split(" ").join("_");

        let a = parentPath.path.substring(0, parentPath.path.indexOf("/"));
        this.userService
          .getFolderTitle(a.split("_").join(" "))
          .subscribe((d) => {
            //Get the last value of slash
            var rest = parentPath.path.substring(
              0,
              parentPath.path.lastIndexOf("/") + 1
            );
            this.userService.getFolderById(d[0].user_id).subscribe((data) => {
              console.log(data);

              for (let i = 0; i < data.length; i++) {
                let a = data[i].path
                  .split(parentPath.path)
                  .join(rest.concat(new_text));
                if (data[i].path === parentPath.path) {
                  let feed = {
                    title: message,
                    path: a,
                    oldpath: parentPath.path,
                  };
                  this.userService
                    .updateFolder(data[i].id, feed)
                    .subscribe((data) => {
                      this.listFolder();
                      this.load = false;
                    });
                } else if (data[i].path.includes(parentPath.path)) {
                  let feed = {
                    path: a,
                    oldpath: parentPath.path,
                  };
                  this.userService
                    .updateFolder(data[i].id, feed)
                    .subscribe((data) => {
                      this.listFolder();
                      this.load = false;
                    });
                }
              }
            });
          });
      }
    });
  }

  downloadFile(el) {
    let x = el.path;
    var f = x.substr(0, x.lastIndexOf(".")) || x;
    var n = el.path.lastIndexOf("/");
    var result = el.path.substring(n + 1);
    console.log(result);
    var iz = el.path.split("/").join("|");
    this.userService.downloadFile(iz).subscribe((data) => {
      const blob = new Blob([data]);

      var downloadURL = window.URL.createObjectURL(data);
      console.log(data);
      console.log(blob);
      var link = document.createElement("a");
      link.href = downloadURL;
      link.download = result;
      link.click();
    });
  }

  pdfSrc = "";
  pageVariable = 1;

  nextPage() {
    this.pageVariable++;
  }
  prevPage() {
    if (this.pageVariable > 1) {
      this.pageVariable--;
    }
  }
  onFileSelected() {
    let $img: any = document.querySelector("#file");

    if (typeof FileReader !== "undefined") {
      let reader = new FileReader();

      reader.onload = (e: any) => {
        console.log(e);

        this.pdfSrc = e.target.result;
      };

      reader.readAsArrayBuffer($img.files[0]);
    }
  }
}
