import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from './../../environments/environment';
import { Item } from '../model/item';
import { User } from '../model/user';
import { map , tap, shareReplay} from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const httpDownload = {
  headers: new HttpHeaders({ 'Content-Type': 'application/pdf' })
};
@Injectable({
  providedIn: 'root'
})

export class UserService {
  private url = (environment as any).urlApi;

  constructor(private http: HttpClient) { }

  getFolderTitle(title): Observable<any> {
    return this.http.get<any>(this.url + 'auth/folderTitle/'+title, httpOptions);
  }
  getFolder(): Observable<Item[]> {
    return this.http.get<Item[]>(this.url + 'auth/listFolder', httpOptions);
    
  }
  getShare(): Observable<any> {
    return this.http.get<any>(this.url + 'auth/getShare', httpOptions);
    
  }
  shareFolder(folder_id): Observable<any> {
    return this.http.post<any>(this.url + 'auth/postShare', {
      folder_id: folder_id,
    }, httpOptions);
    
  }
  
  getFolderById(id): Observable<any> {
    return this.http.get<any>(this.url + 'auth/listFolder/'+id, httpOptions);
  }
  downloadFile(path): Observable<any> {
    //return this.http.get(this.url + 'auth/download', { responseType: 'blob'});
    return this.http.get(this.url +'auth/download/' + path, { responseType: 'blob', observe: 'response'}).pipe(
      map((res: any) => {
        return new Blob([res.body]);
      }, httpOptions)
    )
  }
  showPdf(path): Observable<any> {
    //return this.http.get(this.url + 'auth/download', { responseType: 'blob'});
    return this.http.get(this.url +'auth/showPdf/' + path, { responseType: 'blob', observe: 'response'}).pipe(
      map((res: any) => {
        return new Blob([res.body], { type: 'application/pdf' })
      }, httpOptions)
    )
  }

  postFolder(folder): Observable<any> {
    return this.http.post(this.url + 'auth/createFolder', {
      title: folder.title,
      parent: folder.parent,
      path: folder.path,
      isFolder: folder.isFolder,
    }, httpOptions);
    
  }

  upload(data): Observable<any> {
    return this.http.post<any>(this.url + 'auth/upload', data, httpOptions);
  }

  getProfile(): Observable<User> {
    return this.http.get<User>(this.url + 'auth/profile', httpOptions);
  }
  getUser(): Observable<User> {
    return this.http.get<User>(this.url + 'auth/list-user', httpOptions);
  }
  updatePassword(id, data): Observable<User> {
    return this.http.post<any>(this.url + 'auth/change-password/' + id, data, httpOptions);
  }
  updateUser(id, data): Observable<User> {
    return this.http.put<any>(this.url + 'auth/update-user/' + id, data, httpOptions);
  }
  deleteUser(id): Observable<any> {
    return this.http.delete<any>(this.url + 'auth/delete-user/'+id, httpOptions);
  }
  findUser(id): Observable<any> {
    return this.http.get<any>(this.url + 'auth/find-user/'+id, httpOptions);
  }
  updateFolder(id, data): Observable<User> {
    return this.http.put<any>(this.url + 'auth/update-folder/' + id, data, httpOptions);
  }
  deleteFolder(id): Observable<any> {
    return this.http.delete<any>(this.url + 'auth/delete-folder/' + id, httpOptions);
  }
  removeFolder(data): Observable<any> {
    return this.http.post<any>(this.url + 'auth/remove-folder', data, httpOptions);
  }
  emailForgot(data): Observable<any> {
    return this.http.post<any>(this.url + 'auth/emailForgot', data, httpOptions);
  }
  getIp(): Observable<any> {
    return this.http.get('https://api.db-ip.com/v2/free/self');
  }
  historic(data): Observable<any> {
    return this.http.post<any>(this.url + 'auth/historic', data, httpOptions);
  }
  listHistoric(): Observable<any> {
    return this.http.get<any>(this.url + 'auth/list-historic');
    
  }
}
